
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Comercial",
    "icon": "pi pi-wallet",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/clientes",
        "label": "Clientes",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cargos",
        "label": "Cargos",
        "icon": "pi pi-briefcase",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/segmentos",
        "label": "Segmentos",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/productos",
        "label": "Productos",
        "icon": "pi pi-building",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Config. comerciales",
    "icon": "pi pi-folder-open",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/acabados",
        "label": "Acabados",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipomateriales",
        "label": "Tipo Materiales",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/lineasproductos",
        "label": "Lineas Productos",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/productosyservicios",
        "label": "Productos y servicios",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/areasintervencion",
        "label": "Áreas de intervención",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipoproyectos",
        "label": "Tipo Proyectos",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipoclientes",
        "label": "Tipo Cientes",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/espesores",
        "label": "Espesores",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/siglaspaneles",
        "label": "Siglas paneles",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/siglasruteo",
        "label": "Siglas Ruteo",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Config. generales",
    "icon": "pi pi-cog",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/procesosinternos",
        "label": "Procesos Internos",
        "icon": "pi pi-history",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/cotizacionestados",
        "label": "Estados cotizaciones",
        "icon": "pi pi-file-edit",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/metodospago",
        "label": "Metodos de pago",
        "icon": "pi pi-dollar",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipoidentificaciones",
        "label": "Tipos identificación",
        "icon": "pi pi-id-card",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipodocumentos",
        "label": "Tipos documentos",
        "icon": "pi pi-file-pdf",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/tipoinsumo",
        "label": "Tipo Insumo",
        "icon": "pi pi-shopping-cart",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "",
    "label": "Sistema",
    "icon": "pi pi-database",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/users",
        "label": "Usuarios",
        "icon": "pi pi-users",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/roles",
        "label": "Roles",
        "icon": "pi pi-key",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/audits",
        "label": "Auditoria",
        "icon": "pi pi-lock",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/configuraciongeneral",
        "label": "Configuracion General",
        "icon": "pi pi-th-large",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  }
],
	estado: [    
{value: "Activo", label: "Activo"},
	{value: "Inactivo", label: "Inactivo"}
    ],
	operacion: [    
{value: "Entrada", label: "Entrada"},
	{value: "Salida", label: "Salida"}
    ],
	tipo: [    
{value: "Interno", label: "Interno"},
	{value: "Externo", label: "Externo"}
    ],
	facturaElectronica: [    
{value: "Si", label: "Si"},
	{value: "No", label: "No"}
    ],
	tipo2: [    
{value: "Portada", label: "Portada"},
	{value: "Otros", label: "Otros"}
    ],
	destino: [    
{value: "Clientes", label: "Clientes"},
	{value: "Proyectos", label: "Proyectos"}
    ],
	tipoUsuario: [    
{value: "Cliente", label: "Cliente"},
	{value: "Usuario", label: "Usuario"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}