
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//acabados routes
			{
				path: '/acabados/:fieldName?/:fieldValue?',
				name: 'acabadoslist',
				component: () => import('./pages/acabados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/acabados/view/:id', 
				name: 'acabadosview', 
				component: () => import('./pages/acabados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/acabados/add', 
				name: 'acabadosadd', 
				component: () => import('./pages/acabados/add.vue'), 
				props: true
			},
	
			{ 
				path: '/acabados/edit/:id', 
				name: 'acabadosedit', 
				component: () => import('./pages/acabados/edit.vue'), 
				props: true
			},
		

//areasintervencion routes
			{
				path: '/areasintervencion/:fieldName?/:fieldValue?',
				name: 'areasintervencionlist',
				component: () => import('./pages/areasintervencion/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/areasintervencion/view/:id', 
				name: 'areasintervencionview', 
				component: () => import('./pages/areasintervencion/view.vue'), 
				props: true
			},
		
			{ 
				path: '/areasintervencion/add', 
				name: 'areasintervencionadd', 
				component: () => import('./pages/areasintervencion/add.vue'), 
				props: true
			},
	
			{ 
				path: '/areasintervencion/edit/:id', 
				name: 'areasintervencionedit', 
				component: () => import('./pages/areasintervencion/edit.vue'), 
				props: true
			},
		

//audits routes
			{
				path: '/audits/:fieldName?/:fieldValue?',
				name: 'auditslist',
				component: () => import('./pages/audits/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/audits/view/:id', 
				name: 'auditsview', 
				component: () => import('./pages/audits/view.vue'), 
				props: true
			},
		

//cardex routes
			{
				path: '/cardex/:fieldName?/:fieldValue?',
				name: 'cardexlist',
				component: () => import('./pages/cardex/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cardex/view/:id', 
				name: 'cardexview', 
				component: () => import('./pages/cardex/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cardex/add', 
				name: 'cardexadd', 
				component: () => import('./pages/cardex/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cardex/edit/:id', 
				name: 'cardexedit', 
				component: () => import('./pages/cardex/edit.vue'), 
				props: true
			},
		

//cargos routes
			{
				path: '/cargos/:fieldName?/:fieldValue?',
				name: 'cargoslist',
				component: () => import('./pages/cargos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cargos/view/:id', 
				name: 'cargosview', 
				component: () => import('./pages/cargos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cargos/add', 
				name: 'cargosadd', 
				component: () => import('./pages/cargos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cargos/edit/:id', 
				name: 'cargosedit', 
				component: () => import('./pages/cargos/edit.vue'), 
				props: true
			},
		

//clientecontactos routes
			{
				path: '/clientecontactos/:fieldName?/:fieldValue?',
				name: 'clientecontactoslist',
				component: () => import('./pages/clientecontactos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientecontactos/view/:id', 
				name: 'clientecontactosview', 
				component: () => import('./pages/clientecontactos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientecontactos/add', 
				name: 'clientecontactosadd', 
				component: () => import('./pages/clientecontactos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientecontactos/edit/:id', 
				name: 'clientecontactosedit', 
				component: () => import('./pages/clientecontactos/edit.vue'), 
				props: true
			},
		

//clientehasdocumentos routes
			{
				path: '/clientehasdocumentos/:fieldName?/:fieldValue?',
				name: 'clientehasdocumentoslist',
				component: () => import('./pages/clientehasdocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientehasdocumentos/view/:id', 
				name: 'clientehasdocumentosview', 
				component: () => import('./pages/clientehasdocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientehasdocumentos/add', 
				name: 'clientehasdocumentosadd', 
				component: () => import('./pages/clientehasdocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientehasdocumentos/edit/:id', 
				name: 'clientehasdocumentosedit', 
				component: () => import('./pages/clientehasdocumentos/edit.vue'), 
				props: true
			},
		

//clientes routes
			{
				path: '/clientes/:fieldName?/:fieldValue?',
				name: 'clienteslist',
				component: () => import('./pages/clientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clientes/view/:id', 
				name: 'clientesview', 
				component: () => import('./pages/clientes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clientes/add', 
				name: 'clientesadd', 
				component: () => import('./pages/clientes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clientes/edit/:id', 
				name: 'clientesedit', 
				component: () => import('./pages/clientes/edit.vue'), 
				props: true
			},
		

//configuraciongeneral routes
			{
				path: '/configuraciongeneral/:fieldName?/:fieldValue?',
				name: 'configuraciongenerallist',
				component: () => import('./pages/configuraciongeneral/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/configuraciongeneral/view/:id', 
				name: 'configuraciongeneralview', 
				component: () => import('./pages/configuraciongeneral/view.vue'), 
				props: true
			},
		
			{ 
				path: '/configuraciongeneral/edit/:id', 
				name: 'configuraciongeneraledit', 
				component: () => import('./pages/configuraciongeneral/edit.vue'), 
				props: true
			},
		

//cotizaciones routes
			{
				path: '/cotizaciones/:fieldName?/:fieldValue?',
				name: 'cotizacioneslist',
				component: () => import('./pages/cotizaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizaciones/view/:id', 
				name: 'cotizacionesview', 
				component: () => import('./pages/cotizaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/add', 
				name: 'cotizacionesadd', 
				component: () => import('./pages/cotizaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizaciones/edit/:id', 
				name: 'cotizacionesedit', 
				component: () => import('./pages/cotizaciones/edit.vue'), 
				props: true
			},
		

//cotizacionestados routes
			{
				path: '/cotizacionestados/:fieldName?/:fieldValue?',
				name: 'cotizacionestadoslist',
				component: () => import('./pages/cotizacionestados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizacionestados/view/:id', 
				name: 'cotizacionestadosview', 
				component: () => import('./pages/cotizacionestados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizacionestados/add', 
				name: 'cotizacionestadosadd', 
				component: () => import('./pages/cotizacionestados/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizacionestados/edit/:id', 
				name: 'cotizacionestadosedit', 
				component: () => import('./pages/cotizacionestados/edit.vue'), 
				props: true
			},
		

//espesores routes
			{
				path: '/espesores/:fieldName?/:fieldValue?',
				name: 'espesoreslist',
				component: () => import('./pages/espesores/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/espesores/view/:id', 
				name: 'espesoresview', 
				component: () => import('./pages/espesores/view.vue'), 
				props: true
			},
		
			{ 
				path: '/espesores/add', 
				name: 'espesoresadd', 
				component: () => import('./pages/espesores/add.vue'), 
				props: true
			},
	
			{ 
				path: '/espesores/edit/:id', 
				name: 'espesoresedit', 
				component: () => import('./pages/espesores/edit.vue'), 
				props: true
			},
		

//geociudades routes
			{
				path: '/geociudades/:fieldName?/:fieldValue?',
				name: 'geociudadeslist',
				component: () => import('./pages/geociudades/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geociudades/view/:id', 
				name: 'geociudadesview', 
				component: () => import('./pages/geociudades/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geociudades/add', 
				name: 'geociudadesadd', 
				component: () => import('./pages/geociudades/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geociudades/edit/:id', 
				name: 'geociudadesedit', 
				component: () => import('./pages/geociudades/edit.vue'), 
				props: true
			},
		

//geodepartamentos routes
			{
				path: '/geodepartamentos/:fieldName?/:fieldValue?',
				name: 'geodepartamentoslist',
				component: () => import('./pages/geodepartamentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geodepartamentos/view/:id', 
				name: 'geodepartamentosview', 
				component: () => import('./pages/geodepartamentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geodepartamentos/add', 
				name: 'geodepartamentosadd', 
				component: () => import('./pages/geodepartamentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geodepartamentos/edit/:id', 
				name: 'geodepartamentosedit', 
				component: () => import('./pages/geodepartamentos/edit.vue'), 
				props: true
			},
		

//geopaises routes
			{
				path: '/geopaises/:fieldName?/:fieldValue?',
				name: 'geopaiseslist',
				component: () => import('./pages/geopaises/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/geopaises/view/:id', 
				name: 'geopaisesview', 
				component: () => import('./pages/geopaises/view.vue'), 
				props: true
			},
		
			{ 
				path: '/geopaises/add', 
				name: 'geopaisesadd', 
				component: () => import('./pages/geopaises/add.vue'), 
				props: true
			},
	
			{ 
				path: '/geopaises/edit/:id', 
				name: 'geopaisesedit', 
				component: () => import('./pages/geopaises/edit.vue'), 
				props: true
			},
		

//lineasproductos routes
			{
				path: '/lineasproductos/:fieldName?/:fieldValue?',
				name: 'lineasproductoslist',
				component: () => import('./pages/lineasproductos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/lineasproductos/view/:id', 
				name: 'lineasproductosview', 
				component: () => import('./pages/lineasproductos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/lineasproductos/add', 
				name: 'lineasproductosadd', 
				component: () => import('./pages/lineasproductos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/lineasproductos/edit/:id', 
				name: 'lineasproductosedit', 
				component: () => import('./pages/lineasproductos/edit.vue'), 
				props: true
			},
		

//metodospago routes
			{
				path: '/metodospago/:fieldName?/:fieldValue?',
				name: 'metodospagolist',
				component: () => import('./pages/metodospago/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/metodospago/view/:id', 
				name: 'metodospagoview', 
				component: () => import('./pages/metodospago/view.vue'), 
				props: true
			},
		
			{ 
				path: '/metodospago/add', 
				name: 'metodospagoadd', 
				component: () => import('./pages/metodospago/add.vue'), 
				props: true
			},
	
			{ 
				path: '/metodospago/edit/:id', 
				name: 'metodospagoedit', 
				component: () => import('./pages/metodospago/edit.vue'), 
				props: true
			},
		

//permissions routes

//permissionslist routes

//procesosinternos routes
			{
				path: '/procesosinternos/:fieldName?/:fieldValue?',
				name: 'procesosinternoslist',
				component: () => import('./pages/procesosinternos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/procesosinternos/view/:id', 
				name: 'procesosinternosview', 
				component: () => import('./pages/procesosinternos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/procesosinternos/add', 
				name: 'procesosinternosadd', 
				component: () => import('./pages/procesosinternos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/procesosinternos/edit/:id', 
				name: 'procesosinternosedit', 
				component: () => import('./pages/procesosinternos/edit.vue'), 
				props: true
			},
		

//productos routes
			{
				path: '/productos/:fieldName?/:fieldValue?',
				name: 'productoslist',
				component: () => import('./pages/productos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/productos/view/:id', 
				name: 'productosview', 
				component: () => import('./pages/productos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/productos/add', 
				name: 'productosadd', 
				component: () => import('./pages/productos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/productos/edit/:id', 
				name: 'productosedit', 
				component: () => import('./pages/productos/edit.vue'), 
				props: true
			},
		

//productosasociados routes
			{
				path: '/productosasociados/:fieldName?/:fieldValue?',
				name: 'productosasociadoslist',
				component: () => import('./pages/productosasociados/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/productosasociados/view/:id', 
				name: 'productosasociadosview', 
				component: () => import('./pages/productosasociados/view.vue'), 
				props: true
			},
		
			{ 
				path: '/productosasociados/add', 
				name: 'productosasociadosadd', 
				component: () => import('./pages/productosasociados/add.vue'), 
				props: true
			},
	
			{ 
				path: '/productosasociados/edit/:id', 
				name: 'productosasociadosedit', 
				component: () => import('./pages/productosasociados/edit.vue'), 
				props: true
			},
		

//productosyservicios routes
			{
				path: '/productosyservicios/:fieldName?/:fieldValue?',
				name: 'productosyservicioslist',
				component: () => import('./pages/productosyservicios/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/productosyservicios/view/:id', 
				name: 'productosyserviciosview', 
				component: () => import('./pages/productosyservicios/view.vue'), 
				props: true
			},
		
			{ 
				path: '/productosyservicios/add', 
				name: 'productosyserviciosadd', 
				component: () => import('./pages/productosyservicios/add.vue'), 
				props: true
			},
	
			{ 
				path: '/productosyservicios/edit/:id', 
				name: 'productosyserviciosedit', 
				component: () => import('./pages/productosyservicios/edit.vue'), 
				props: true
			},
		

//proyectohasdocumentos routes
			{
				path: '/proyectohasdocumentos/:fieldName?/:fieldValue?',
				name: 'proyectohasdocumentoslist',
				component: () => import('./pages/proyectohasdocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectohasdocumentos/view/:id', 
				name: 'proyectohasdocumentosview', 
				component: () => import('./pages/proyectohasdocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectohasdocumentos/add', 
				name: 'proyectohasdocumentosadd', 
				component: () => import('./pages/proyectohasdocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectohasdocumentos/edit/:id', 
				name: 'proyectohasdocumentosedit', 
				component: () => import('./pages/proyectohasdocumentos/edit.vue'), 
				props: true
			},
		

//proyectohasresponsables routes
			{
				path: '/proyectohasresponsables/:fieldName?/:fieldValue?',
				name: 'proyectohasresponsableslist',
				component: () => import('./pages/proyectohasresponsables/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectohasresponsables/view/:id', 
				name: 'proyectohasresponsablesview', 
				component: () => import('./pages/proyectohasresponsables/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectohasresponsables/add', 
				name: 'proyectohasresponsablesadd', 
				component: () => import('./pages/proyectohasresponsables/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectohasresponsables/edit/:id', 
				name: 'proyectohasresponsablesedit', 
				component: () => import('./pages/proyectohasresponsables/edit.vue'), 
				props: true
			},
		

//proyectoimagenes routes
			{
				path: '/proyectoimagenes/:fieldName?/:fieldValue?',
				name: 'proyectoimageneslist',
				component: () => import('./pages/proyectoimagenes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectoimagenes/view/:id', 
				name: 'proyectoimagenesview', 
				component: () => import('./pages/proyectoimagenes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectoimagenes/add', 
				name: 'proyectoimagenesadd', 
				component: () => import('./pages/proyectoimagenes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectoimagenes/edit/:id', 
				name: 'proyectoimagenesedit', 
				component: () => import('./pages/proyectoimagenes/edit.vue'), 
				props: true
			},
		

//proyectos routes
			{
				path: '/proyectos/:fieldName?/:fieldValue?',
				name: 'proyectoslist',
				component: () => import('./pages/proyectos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/proyectos/view/:id', 
				name: 'proyectosview', 
				component: () => import('./pages/proyectos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/proyectos/add', 
				name: 'proyectosadd', 
				component: () => import('./pages/proyectos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/proyectos/edit/:id', 
				name: 'proyectosedit', 
				component: () => import('./pages/proyectos/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//segmentos routes
			{
				path: '/segmentos/:fieldName?/:fieldValue?',
				name: 'segmentoslist',
				component: () => import('./pages/segmentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/segmentos/view/:id', 
				name: 'segmentosview', 
				component: () => import('./pages/segmentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/segmentos/add', 
				name: 'segmentosadd', 
				component: () => import('./pages/segmentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/segmentos/edit/:id', 
				name: 'segmentosedit', 
				component: () => import('./pages/segmentos/edit.vue'), 
				props: true
			},
		

//siglaspaneles routes
			{
				path: '/siglaspaneles/:fieldName?/:fieldValue?',
				name: 'siglaspaneleslist',
				component: () => import('./pages/siglaspaneles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/siglaspaneles/view/:id', 
				name: 'siglaspanelesview', 
				component: () => import('./pages/siglaspaneles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/siglaspaneles/add', 
				name: 'siglaspanelesadd', 
				component: () => import('./pages/siglaspaneles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/siglaspaneles/edit/:id', 
				name: 'siglaspanelesedit', 
				component: () => import('./pages/siglaspaneles/edit.vue'), 
				props: true
			},
		

//siglasruteo routes
			{
				path: '/siglasruteo/:fieldName?/:fieldValue?',
				name: 'siglasruteolist',
				component: () => import('./pages/siglasruteo/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/siglasruteo/view/:id', 
				name: 'siglasruteoview', 
				component: () => import('./pages/siglasruteo/view.vue'), 
				props: true
			},
		
			{ 
				path: '/siglasruteo/add', 
				name: 'siglasruteoadd', 
				component: () => import('./pages/siglasruteo/add.vue'), 
				props: true
			},
	
			{ 
				path: '/siglasruteo/edit/:id', 
				name: 'siglasruteoedit', 
				component: () => import('./pages/siglasruteo/edit.vue'), 
				props: true
			},
		

//tipoclientes routes
			{
				path: '/tipoclientes/:fieldName?/:fieldValue?',
				name: 'tipoclienteslist',
				component: () => import('./pages/tipoclientes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoclientes/view/:id', 
				name: 'tipoclientesview', 
				component: () => import('./pages/tipoclientes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoclientes/add', 
				name: 'tipoclientesadd', 
				component: () => import('./pages/tipoclientes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoclientes/edit/:id', 
				name: 'tipoclientesedit', 
				component: () => import('./pages/tipoclientes/edit.vue'), 
				props: true
			},
		

//tipodocumentos routes
			{
				path: '/tipodocumentos/:fieldName?/:fieldValue?',
				name: 'tipodocumentoslist',
				component: () => import('./pages/tipodocumentos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipodocumentos/view/:id', 
				name: 'tipodocumentosview', 
				component: () => import('./pages/tipodocumentos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipodocumentos/add', 
				name: 'tipodocumentosadd', 
				component: () => import('./pages/tipodocumentos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipodocumentos/edit/:id', 
				name: 'tipodocumentosedit', 
				component: () => import('./pages/tipodocumentos/edit.vue'), 
				props: true
			},
		

//tipoidentificaciones routes
			{
				path: '/tipoidentificaciones/:fieldName?/:fieldValue?',
				name: 'tipoidentificacioneslist',
				component: () => import('./pages/tipoidentificaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoidentificaciones/view/:id', 
				name: 'tipoidentificacionesview', 
				component: () => import('./pages/tipoidentificaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoidentificaciones/add', 
				name: 'tipoidentificacionesadd', 
				component: () => import('./pages/tipoidentificaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoidentificaciones/edit/:id', 
				name: 'tipoidentificacionesedit', 
				component: () => import('./pages/tipoidentificaciones/edit.vue'), 
				props: true
			},
		

//tipoinsumo routes
			{
				path: '/tipoinsumo/:fieldName?/:fieldValue?',
				name: 'tipoinsumolist',
				component: () => import('./pages/tipoinsumo/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoinsumo/view/:id', 
				name: 'tipoinsumoview', 
				component: () => import('./pages/tipoinsumo/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoinsumo/add', 
				name: 'tipoinsumoadd', 
				component: () => import('./pages/tipoinsumo/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoinsumo/edit/:id', 
				name: 'tipoinsumoedit', 
				component: () => import('./pages/tipoinsumo/edit.vue'), 
				props: true
			},
		

//tipomateriales routes
			{
				path: '/tipomateriales/:fieldName?/:fieldValue?',
				name: 'tipomaterialeslist',
				component: () => import('./pages/tipomateriales/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipomateriales/view/:id', 
				name: 'tipomaterialesview', 
				component: () => import('./pages/tipomateriales/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipomateriales/add', 
				name: 'tipomaterialesadd', 
				component: () => import('./pages/tipomateriales/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipomateriales/edit/:id', 
				name: 'tipomaterialesedit', 
				component: () => import('./pages/tipomateriales/edit.vue'), 
				props: true
			},
		

//tipoproyectos routes
			{
				path: '/tipoproyectos/:fieldName?/:fieldValue?',
				name: 'tipoproyectoslist',
				component: () => import('./pages/tipoproyectos/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/tipoproyectos/view/:id', 
				name: 'tipoproyectosview', 
				component: () => import('./pages/tipoproyectos/view.vue'), 
				props: true
			},
		
			{ 
				path: '/tipoproyectos/add', 
				name: 'tipoproyectosadd', 
				component: () => import('./pages/tipoproyectos/add.vue'), 
				props: true
			},
	
			{ 
				path: '/tipoproyectos/edit/:id', 
				name: 'tipoproyectosedit', 
				component: () => import('./pages/tipoproyectos/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usersuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

//visitastecnicas routes
			{
				path: '/visitastecnicas/:fieldName?/:fieldValue?',
				name: 'visitastecnicaslist',
				component: () => import('./pages/visitastecnicas/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/visitastecnicas/view/:id', 
				name: 'visitastecnicasview', 
				component: () => import('./pages/visitastecnicas/view.vue'), 
				props: true
			},
		
			{ 
				path: '/visitastecnicas/add', 
				name: 'visitastecnicasadd', 
				component: () => import('./pages/visitastecnicas/add.vue'), 
				props: true
			},
	
			{ 
				path: '/visitastecnicas/edit/:id', 
				name: 'visitastecnicasedit', 
				component: () => import('./pages/visitastecnicas/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/documentos', 
				name: 'documentos', 
				component: () => import('././pages/custom/documentos.vue'), 
				props: true
			},
	
			{ 
				path: '/visitatecnica', 
				name: 'visitatecnica', 
				component: () => import('././pages/custom/visitatecnica.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
